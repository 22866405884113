// Images file for 'lenderqualified'.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Checkmark from '../../../../src/images/checkmark-blue.png';
import Dropdown from '../../../../src/images/dropdown_carat.png';

export default {
  Favicon,
  Checkmark,
  Dropdown,
};
